import { render, staticRenderFns } from "./categorylevels.vue?vue&type=template&id=4224da34&scoped=true&"
import script from "./categorylevels.vue?vue&type=script&lang=js&"
export * from "./categorylevels.vue?vue&type=script&lang=js&"
import style0 from "./categorylevels.vue?vue&type=style&index=0&id=4224da34&lang=scss&scoped=true&"
import style1 from "./categorylevels.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4224da34",
  null
  
)

export default component.exports