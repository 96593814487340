<template>
  <b-sidebar
    id="add-edit-level-sidebar"
    :visible="isAddEditLevelSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-edit-level-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Edit level</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->

          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group label="Name" label-for="Name">
              <b-form-input
                id="Name"
                type="number"
                v-model="leveldata.level_name"
                autofocus
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="No of Question"
            rules="required"
          >
            <b-form-group label="No of Question" label-for="No of Question">
              <b-form-input
                id="No of Question"
                type="number"
                v-model="leveldata.NoofQuestion"
                autofocus
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-form-group label="Level Status">
            <!-- v-on:change="changebgtype(selected_bg_type, index)" -->
            <b-form-select v-model="leveldata.selectStatus" :options="options">
            </b-form-select>
          </b-form-group>

          <div
            class="d-flex bg-light text-light align-items px-3 py-2"
            style="position: absolute; bottom: 0%; left: 0%; width: 100%"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              @click="hide"
              :disabled="submitbutton"
            >
              Update
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              style="margin-left: 10px"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BCol,
  BFormFile,
  BFormSelect,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import countries from "../../../../@fake-db/data/other/countries";
import store from "@/store";
import axios from "axios";
import { integer } from "vee-validate/dist/rules";

export default {
  components: {
    BSidebar,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormFile,
    BFormSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddEditLevelSidebarActive",
    event: "update:is-edit-level-user-sidebar-active",
  },
  props: {
    isAddEditLevelSidebarActive: {
      type: Boolean,
      required: true,
    },
    levelidedit: {
      type: String,
      required: true,
    },

    EditLevelname: {
      type: String,
      required: true,
    },
    EditNoofquestion: {
      type: String,
      required: true,
    },
    editLevelStatus: {
      type: String,
      required: true,
    },
  },
  watch: {
    EditLevelname() {
      this.leveldata.level_name = this.EditLevelname;
    },
    EditNoofquestion() {
      this.leveldata.NoofQuestion = this.EditNoofquestion;
    },
    editLevelStatus() {
      this.leveldata.selectStatus = this.editLevelStatus;
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      leveldata: {
        level_id: "",

        level_name: "",
        NoofQuestion: "",
        selectStatus: "",
      },
      options: ["active", "inactive"],
      add_edit_level_data: "",
    };
  },
  computed: {
    submitbutton() {
      // console.log('this.userdata',this.userdata);
      return !(
        this.leveldata.level_name.length > 0 &&
        this.leveldata.NoofQuestion.length > 0 &&
        this.leveldata.selectStatus.length > 0
      );
    },
  },
  // created() {
  //   console.log(this.addlevelclassid);
  // },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    resetForm() {
      this.leveldata = {
        level_name: this.EditLevelname,
        NoofQuestion: this.EditNoofquestion,
        selectStatus: this.editLevelStatus,
      };
    },
    onSubmit() {
      this.leveldata.levelId = this.levelidedit;
      this.add_edit_level_data = this.leveldata;
      // console.log(this.add_edit_level_data);
      this.$emit("clickaddeditlevel", this.add_edit_level_data);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
